<i18n lang="yaml">
ja:
  label: 出展申し込みはこちら
en:
  label: Apply for Exhibition
</i18n>
<template>
  <content-doc class="hm-markdowon">
    <template #not-found>
      <span ref="notFound"></span>
    </template>
  </content-doc>
  <template v-if="slug.includes('exhibition_guideline')">
    <div class="apply-button">
      <HaLink blank class="link" :to="'/2024Winter/mypage/application'">
        {{ i18n.t('label') }}
      </HaLink>
    </div>
  </template>
  <template v-if="slug.includes('exhibition_guideline') && mounted">
    <Teleport to=".main">
      <HoFixedButton />
    </Teleport>
  </template>
  <template v-if="!slug.includes('exhibition_guideline') && mounted">
    <Teleport to=".adjust-padding-container">
      <div class="padding-bottom"></div>
    </Teleport>
  </template>
</template>

<script setup lang="ts">
definePageMeta({
  layout: 'content',
})

const i18n = useI18n()
const route = useRoute()
const slug = String(route.params.slug)
const notFound = ref()

const mounted = ref(false)
onMounted(() => {
  mounted.value = true
  if (notFound.value) {
    throw createError({
      statusCode: 404,
      statusMessage:
        i18n.locale.value === 'ja'
          ? 'ページが見つかりませんでした'
          : 'Page Not Found',
      fatal: true,
    })
  }
})
</script>

<style lang="scss">
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.hm-markdowon {
  padding: 1em;

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: 700;
    line-height: 1.3;
  }

  h1 {
    align-items: center;
    color: v.$v24w-base-07;
    display: flex;
    font-size: 28px;
    margin-bottom: v.$space-large;

    &::before {
      background-color: v.$v24w-base-07;
      content: '';
      flex-grow: 1;
      height: 2px;
      margin-right: 1rem;
    }

    &::after {
      background-color: v.$v24w-base-07;
      content: '';
      flex-grow: 1;
      height: 2px;
      margin-left: 1rem;
    }
  }

  h2 {
    background-color: v.$v24w-base-07;
    font-size: 24px;
    margin-bottom: v.$space-normal;
    padding: 8px 16px;

    > a {
      color: v.$white;
      text-decoration: none;
    }
  }

  h3 {
    background-color: v.$v24w-base-sub;
    font-size: 22px;
    margin-bottom: v.$space-normal;
    padding: 8px 16px;

    > a {
      color: v.$black !important;
      text-decoration: none;
    }
  }

  h4 {
    font-size: 18px;

    > a {
      color: v.$black;
      text-decoration: none;
    }
  }

  h5 {
    font-size: 16px;
  }

  h3,
  h4,
  h5 {
    margin-bottom: v.$space-base;
  }

  ul,
  ol {
    margin-bottom: v.$space-normal;
    margin-left: 1em;

    li {
      &:not(:last-child) {
        margin-bottom: v.$space-base;
      }
    }
  }

  li {
    line-height: 1.3;
    list-style-position: inside;

    > ul,
    ol {
      margin: v.$space-base 0;
      padding-left: v.$space-normal;
    }
  }
  /* stylelint-disable selector-max-compound-selectors */
  ul > li {
    list-style: disc;
    list-style-position: inside;

    ul > li {
      list-style: circle;

      ul > li {
        list-style: disc;
      }
    }
  }
  /* stylelint-ensable selector-max-compound-selectors */
  ol > li {
    list-style: decimal;
    list-style-position: inside;
  }

  ol[type='a'] > li {
    list-style: lower-latin;
    list-style-position: inside;
  }

  p {
    line-height: 1.6;
    margin-bottom: v.$space-normal;
  }

  img {
    display: block;
    margin: v.$space-normal auto;
    max-width: 100%;
    width: fit-content;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    margin: v.$space-normal auto;
    max-width: 100%;
    min-width: 50%;
    width: 100%;
    @include m.sp() {
      font-size: small;
    }
  }

  code {
    background-color: #cdd;
    padding: 2px 5px;
  }

  table th,
  table td {
    padding: 8px 12px;
  }

  table td:first-child {
    white-space: nowrap;
  }

  table tr:nth-child(odd) {
    background-color: v.$gray-7;
  }

  table tr:nth-child(even) {
    background-color: v.$white;
  }

  thead tr:first-child {
    background-color: v.$gray-8;
  }

  hr {
    margin-bottom: v.$space-normal;
  }

  dl {
    line-height: 1.6;
    margin-bottom: v.$space-normal;

    dt {
      font-weight: 700;
      margin-bottom: v.$space-xsmall;
    }

    dd {
      margin-bottom: v.$space-small;
      margin-left: 0.5em;
    }
  }

  .no-header {
    table th {
      display: none;
    }
  }
  @include m.sp() {
    font-size: 15px;
  }
}

.apply-button {
  align-items: center;
  display: flex;
  justify-content: center;

  > .link {
    background-color: v.$v24w-primary-01;
    border-radius: 4px;
    color: v.$v24w-text-white;
    display: inline-block;
    font-weight: 600;
    height: 48px;
    line-height: 48px;
    padding: v.$space-small v.$space-base;
    padding: 0 v.$space-large;
    transition: 0.3s all ease;

    &:hover {
      background-color: v.$v24w-secondary-02;
      color: v.$v24w-text-white;
    }
  }
}
</style>
