<i18n lang="yaml">
ja:
  pc: PCワールド
  quest: Questワールド
  cloud: Webブラウザ会場
  mousikomi: 出展申し込み
  here: はこちら
en:
  pc: PC World
  quest: Quest World
  cloud: Web browser venue
  mousikomi: Apply for
  here: Exhibition
</i18n>
<template>
  <ul v-if="isBeforeEntryDeadline" class="ho-fixed-button">
    <li class="item">
      <HaLink
        class="fix-link"
        :class="{ '-en': i18n.locale.value === 'en' }"
        force-anchor-link
        to="/2024Winter/mypage/application"
      >
        <div class="entry-image">
          <IconComment class="icon" />
        </div>
        <div class="text">
          <span>{{ i18n.t('mousikomi') }}</span
          ><br />
          <span>{{ i18n.t('here') }}</span>
        </div>
      </HaLink>
    </li>
    <li v-if="false" class="item">
      <HaLink
        class="fix-link"
        :class="{ '-en': i18n.locale.value === 'en' }"
        force-anchor-link
        to="/Winter/mypage/status_quest"
      >
        <div class="entry-image">
          <IconHmd class="icon -hmd" />
        </div>
        <div class="text">
          <div>{{ i18n.t('quest') }}</div>
          <div>{{ i18n.t('mousikomi') }}</div>
        </div>
      </HaLink>
    </li>
    <!-- Webブラウザ会場は一旦非表示にしています。必要に応じて削除してください [by nekobata] -->
    <li v-if="false" class="item">
      <HaLink
        class="fix-link"
        :class="{ '-en': i18n.locale.value === 'en' }"
        force-anchor-link
        to="/2024Winter/mypage/status_vket_cloud"
      >
        <div class="entry-image">
          <IconDevice class="icon" />
          <HaImage
            class="icon -cloud"
            src="/2024Winter/images/logo_Vketcloud.svg"
          />
        </div>
        <div class="text">
          <div>{{ i18n.t('cloud') }}</div>
          <div>{{ i18n.t('mousikomi') }}</div>
        </div>
      </HaLink>
    </li>
  </ul>
</template>

<script setup lang="ts">
import IconHmd from '@/assets/icons/icon-hmd.svg'
import IconComment from '@/assets/icons/icon-form.svg'
import IconDevice from '@/assets/icons/icon-device.svg?component'
import { isBeforePcEntryDeadline } from '@/utils/date-control'

const i18n = useI18n()
const isBeforeEntryDeadline = computed(() => isBeforePcEntryDeadline())
</script>

<style lang="scss" scoped>
@use '@/assets/styles/variables' as v;
@use '@/assets/styles/mixins' as m;

.ho-fixed-button {
  bottom: 0;
  display: flex;
  gap: v.$space-normal;
  justify-content: flex-end;
  pointer-events: none;
  position: sticky;
  right: v.$space-large;
  width: auto;
  z-index: v.$zindex-main;
  @include m.sp() {
    gap: v.$space-small;
    justify-content: space-around;
    margin-top: v.$space-large;
    padding: 0 v.$space-small;
    right: 0;
  }

  > .item {
    bottom: v.$space-large;
    pointer-events: all;
    position: relative;
    right: v.$space-large;
    @include m.sp() {
      bottom: v.$space-small;
      min-width: 264px;
      right: 0;
      width: 50%;
    }
  }
}

.fix-link {
  align-items: center;
  background-color: v.$v24w-secondary-04;
  border: 4px solid v.$white;
  border-radius: 100vh;
  box-shadow: 0 0 24px rgba(v.$black, 0.3);
  color: v.$white;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 700;
  height: 192px;
  justify-content: center;
  padding: v.$space-base v.$space-normal;
  text-align: center;
  transition: background-color 0.3s ease-out;
  width: 192px;

  @include m.hover {
    background-color: v.$v24w-secondary-03;
  }
  @include m.sp() {
    flex-direction: row;
    font-size: 14px;
    height: auto;
    padding: v.$space-small v.$space-small;
    width: auto;
  }

  .text {
    @include m.sp() {
      font-size: 1.2em;
      width: 74%;

      br {
        display: none;
      }
    }
  }
}

.entry-image {
  display: flex;
  gap: v.$space-small;
  height: 44%;
  justify-content: space-around;
  width: 100%;
  @include m.sp() {
    height: 40px;
    width: 26%;
  }

  .icon {
    fill: v.$purple;
    height: 100%;
    max-height: 52px;
    width: 100%;
    @include m.sp() {
      min-height: 40px;
      width: 100%;
    }
  }
}
</style>
